import Vue from 'vue';
import { HTTP } from "@/axios.js"

Vue.mixin({
    methods: {
        currencyFormatter(value,addmargin=false) {
            const options = {
              significantDigits: 2,
              thousandsSeparator: ",",
              decimalSeparator: ".",
              symbol: "",
            };
        
            if (typeof value !== "number") value = parseFloat(value);

            if (addmargin) {
              value= value +  (value * parseFloat(process.env.VUE_APP_BUYER_CHARGE_PERC));
            }

            value = value.toFixed(options.significantDigits);

        
            const [currency, decimal] = value.split(".");
            return `${options.symbol} ${currency.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              options.thousandsSeparator
            )}${options.decimalSeparator}${decimal}`;
        },
        capitalizeFirstLetter(string) {
          return string.charAt(0).toUpperCase() + string.slice(1);
        },
        updateProfile() {

          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.$store.state.token
          }

          HTTP.get('/api/get-profile', { headers: headers }).then(
            (rsp) => {
              
              if (rsp.data.statuscode == 200) {

                // this.$store.state.user = rsp.data.data.user;
                this.$store.dispatch('storeUserData', { userdata: rsp.data.data.user })
                
              }


            }).catch((response) => {
              if (response.response.status == 401) {
                this.$store.dispatch('login', { token: '' })
                this.$router.push('/login')
                this.$store.dispatch('storePortfolioData', {});            
              }
            });
                 
        }
      }
  })