import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMaterial from "vue-material";
import Vuelidate from 'vuelidate';

import "./index.css";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VueTailwind from "vue-tailwind";
import 'tw-elements';
import {
  TInput,
  TTextarea,
  // TSelect,
  // TRadio,
  // TCheckbox,
  // TButton,
  // TInputGroup,
  // TCard,
  // TAlert,
  // TModal,
  TDropdown,
  // TRichSelect,
  TPagination,
  // TTag,
  // TRadioGroup,
  // TCheckboxGroup,
  // TTable,
  // TDatepicker,
  // TToggle,
  // TDialog,
} from "vue-tailwind/dist/components";

const settings = {
  // Use the following syntax
  // {component-name}: {
  //   component: {importedComponentObject},
  //   props: {
  //     {propToOverride}: {newDefaultValue}
  //     {propToOverride2}: {newDefaultValue2}
  //   }
  // }
  "t-dropdown": {
    component: TDropdown,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
      // ...More settings
    },
  },
  "t-input": {
    component: TInput,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
      // ...More settings
    },
  },
  "t-textarea": {
    component: TTextarea,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
      // ...More settings
    },
  },
  't-pagination': {
    component: TPagination,
    props: {
      classes: {
        wrapper: 'table border-collapse text-center bg-white mx-auto shadow-sm m-5',
        element: 'w-8 h-8 border border-gray-200 table-cell hover:border-blue-100 ',
        activeElement: 'w-8 h-8 border border-gray-200 border-blue-500 table-cell hover:border-blue-600',
        disabledElement: 'w-8 h-8 border border-gray-200 table-cell',
        ellipsisElement: 'w-8 h-8 border border-gray-200 hidden md:table-cell',
        activeButton: 'bg-blue-500 w-full h-full text-white hover:bg-blue-600 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        disabledButton: 'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out',
        button: 'hover:bg-blue-100 w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
        ellipsis: ''
      },
      variants: {
        rounded: {
          wrapper: 'bg-white mx-auto text-center flex space-x-2 place-items-center',
          element: 'w-8 h-8 rounded-full',
          activeElement: 'w-8 h-8 rounded-full',
          disabledElement: 'w-8 h-8 rounded-full',
          ellipsisElement: 'w-8 h-8 rounded-full hidden md:inline',
          activeButton: 'border border-black bg-black w-full h-full rounded-full text-white hover:bg-black transition duration-100 ease-in-out focus:ring-2 focus:ring-black focus:outline-none focus:ring-opacity-50 base-font',
          disabledButton: 'border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out base-font',
          button: 'border border-gray-200 hover:bg-black hover:border-black rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-black focus:outline-none hover:text-white focus:ring-opacity-50  base-font',
          ellipsis: ''
        }
      }
    }

  },
  // ...Rest of the components
};

Vue.use(VueTailwind, settings);

Vue.use(VueMaterial);
Vue.use(Vuelidate);

// change multiple options
Vue.material = {
  ...Vue.material,
  locale: {
    ...Vue.material.locale,
    dateFormat: "dd/MM/yyyy",
    firstDayOfAWeek: 1,
  },
};

import Notifications from "vue-notification";
Vue.use(Notifications);

Vue.config.productionTip = false;

// Vuex Store
import store from "./store";

// Helper Functions
import "./helpers/helper";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
