import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    token: null,
    user: null,
    portfolio: null,
    retailorder: null,
    orderData: {},
    market: 'oenohouse'
  },
  mutations: {
    login(state, userData) {
      state.token = userData.token;
    },
    storeUserData(state, userData) {
      state.user = userData.userdata;
    },
    storePortfolioData(state, portfolioData) {
      state.portfolio = portfolioData.portfoliodata;
      state.retailorder = portfolioData.retailorderdata;
    },
    storeOrderData(state, orderData) {
      state.orderData = orderData.orderData;
    },
    storeMarketData(state, marketData) {
      state.marketData = marketData.marketData;
    },
  },
  actions: {
    login({ commit }, authData) {
      commit("login", {
        token: authData.token,
      });
    },
    storeUserData({ commit }, userData) {
      commit("storeUserData", {
        userdata: userData.userdata,
      });
    },
    storePortfolioData({ commit }, portfolioData) {

      console.log(portfolioData.portfoliodata)
      console.log(portfolioData.portfoliodata.investment)
      console.log(portfolioData.portfoliodata.retailOrders)
      commit("storePortfolioData", {
        portfoliodata: portfolioData.portfoliodata.investment,
        retailorderdata: portfolioData.portfoliodata.retailOrders
      });
    },
    storeOrderData({ commit }, orderData) {
      commit("storeOrderData", {
        orderData: orderData,
      });
    },
    storeMarketData({ commit }, marketData) {
      commit("storeMarketData", {
        marketData: marketData,
      });
    },    
  },
});
