import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'page-portfolio' }
  },
  {
    path: '/login',
    name: 'page-login',
    component: () => import('@/views/pages/auth/Login.vue')
  },  
  {
    path: '/forgot',
    name: 'page-forgot',
    component: () => import('@/views/pages/auth/Forgot.vue')
  },  
  {
    path: '/forgotreset',
    name: 'page-forgotreset',
    component: () => import('@/views/pages/auth/OTPPassword.vue')
  },    
  {
    path: '/portfolio',
    name: 'page-portfolio',
    component: () => import('@/views/pages/portfolio/View.vue'),
    props: true,
  },  

  {
    path: '/analytics',
    name: 'page--analytics',
    component: () => import('@/views/pages/portfolio/Analytics.vue')
  },   
  {
    path: '/profile',
    name: 'page--profile',
    component: () => import('@/views/pages/profile/Profile.vue')
  },
  {
    path: '/portfoliodetail/:id/:orderId',
    name: 'page-portfolio-detail',
    component: () => import('@/views/pages/portfolio/Detail.vue')
  },    
  {
    path: '/brokerdetail',
    name: 'page-broker-detail',
    component: () => import('@/views/pages/broker/View.vue')
  },      
  
  /*
    Payment URLS
  */
  {
    path: '/managepayments',
    name: 'page--managepayments',
    component: () => import('@/views/pages/payments/View.vue')
  },   
  {
    path: '/volt_callback/success',
    name: 'page--voltcallback-success',
    component: () => import('@/views/pages/payments/Success.vue')
  },   
  {
    path: '/volt_callback/failure',
    name: 'page--voltcallback-failure',
    component: () => import('@/views/pages/payments/Failure.vue')
  },   
    
  /*
    Buy URLS
  */
    {
      path: '/stock/:type',
      name: 'page--stock',
      component: () => import('@/views/pages/buy/View.vue'),
      props: true,
    },
    /*
      Investment routes
    */
      {
        path: '/buy/:id/:source/:sell_size',
        name: 'page--buy--stock',
        component: () => import('@/views/pages/buy/invest/Buy.vue')
      },       
      {
        path: '/buy/target/:id/:source/:sell_size',
        name: 'page--buy--invest-target',
        component: () => import('@/views/pages/buy/invest/Target.vue')
      },       
      {
        path: '/buy/outhome/:id/:source/:sell_size',
        name: 'page--buy--invest-outhome',
        component: () => import('@/views/pages/buy/invest/DeliveryHome.vue')
      },       
      {
        path: '/buy/outbond/:id/:source/:sell_size',
        name: 'page--buy--invest-outbond',
        component: () => import('@/views/pages/buy/invest/DeliveryBond.vue')
      },       
      {
        path: '/buy/pay/:id/:source/:sell_size',
        name: 'page--buy--invest-pay',
        component: () => import('@/views/pages/buy/invest/PayAndConfirm.vue')
      },       
        /*
      Retail routes
    */
      {
      path: '/buyretail/:id/:source/:sell_size',
      name: 'page--buy--oh',
      component: () => import('@/views/pages/buy/Retail/BuyOH.vue')
    },       
    {
      path: '/buyretail/delivery/:id/:source/:sell_size',
      name: 'page--buy--oh-delivery',
      component: () => import('@/views/pages/buy/Retail/Delivery.vue')
    },       
    {
      path: '/buyretail/success',
      name: 'page--buy--oh-success',
      component: () => import('@/views/pages/buy/Success.vue')
    },       
    {
      path: '/buyretail/fail',
      name: 'page--buy--oh-fail',
      component: () => import('@/views/pages/buy/Retail/Fail.vue')
    },       
  
  /*
    Sell URLS
  */
    {
      path: '/sell/:id/:orderId',
      name: 'page--sell',
      component: () => import('@/views/pages/sell/View.vue')
    },     
    {
      path: '/sellorder/:id/:orderId',
      name: 'page--sell--order',
      component: () => import('@/views/pages/sell/Order.vue')
    },   

    /*
      Transaction URLS
    */
    {
      path: '/transactions',
      name: 'page--transactions',
      component: () => import('@/views/pages/transactions/View.vue')
    },       
  

  {
      path: '*',
      redirect: '/error-404'
  }  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/error-404','/login','/terms','/privacy','/forgot','/forgotreset'];
  const authRequired = !publicPages.includes(to.path);

  const loggedIn = store.state.token;


  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
})


export default router
